$include-fonts: false;
@import "../setup/settings";

.page--login {
    padding-top: rem-calc(120);
    min-height: 100vh;
    @include breakpoint (medium down){
        padding-top: rem-calc(60);  
    }
    .full-width-page {
        min-height: 80vh;
    }
}
.cc-mainHeader {
    nav {
        &.register-navigation {
            padding: rem-calc(16 0);
            border-bottom: rem-calc(1) solid $border-gray;
        }
    }
}

.abandoned-cart {
    & .tab-content {
        padding-top: rem-calc(100);
    }
}

.ico-back-registration {
    vertical-align: -webkit-baseline-middle;
    .ico-back {
        width: rem-calc(20);
        height: rem-calc(14);
    }
}

.fixed-image-login-no-scroll{
    display: inline;
    position: fixed;
    margin-top: rem-calc(25);
    height: 100%;
    width: 39%;
    margin-left: 9%;
}
.login-cell-image, .register-cell-image {
    width: 100%;
    object-fit: cover;
}
.login-forms-cell {
    border: 0;
    .social-login-form-error{
        margin: rem-calc(33 0 0);
    }
    .login-tabs {
        @include breakpoint (large up){
            max-width: rem-calc(480);
            margin: 0 auto;
        }
        ul {
            @include breakpoint (large up){
                &.li{
                    border-bottom: none;
                }
            }
            &.tabs {
                border-bottom: none;
                text-align: center;
                @include breakpoint (large up){
                    margin-top: rem-calc(30);
                    margin-bottom: rem-calc(40); 
                }
                @include breakpoint (medium down){
                    margin-top: rem-calc(10);
                    margin-bottom: rem-calc(40);
                }
                .tab-link{
                    padding: 0;
                    &:not(.current){
                        .navigation__link{
                            border-color: $cloud;
                            color: $charcoal;
                        }
                    }
                }
            }
        }
    }
    #tab-login-cell{
        @include breakpoint (medium down){
            width: 100%;
        }
        .login-cta{
            margin-bottom: rem-calc(20);
        }
        .modal-title-for-login{
            margin-left: 0;
            margin-bottom: rem-calc(16);
        }
        .social-login-account-accept{
            margin-bottom: rem-calc(40);
            @include breakpoint (medium down){
                margin-bottom: rem-calc(20);
            }
        }
    }
    #tab-registration-cell{
        @include breakpoint (medium down){
            padding-top: 0;
        }
        .register-cta{
            margin-bottom: rem-calc(40);
        }
        .myintimissimi-card-registration-box{
            padding-top: rem-calc(40);
        }
        .social-login-account-accept{
            margin-bottom: rem-calc(40);
        }
        }
    }
    
    .login-title-question,.register-title-question {
        text-align: center;
        font-size: rem-calc(22);
        line-height: rem-calc(28);
        letter-spacing: rem-calc(1);
        width: 100%;
        margin-bottom: rem-calc(10);
        @include breakpoint (large up){
            max-width: rem-calc(480);
        }
    }
    .login-form-container,
    .registration-boxes-container,
    .register-form-container {
        @include breakpoint (medium down){
            padding: rem-calc(0 16);
        }
        .hide-email-only{
            margin-bottom: rem-calc(10);
            @include breakpoint (medium down){
                margin-bottom: rem-calc(10);
            }
        }
    }
    .login-forgot-password-save-access {
        @include breakpoint (medium down){
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .login-save-access {
            vertical-align: bottom;
            flex: 0 0 auto;
        }
        .login-save-access{
            @include breakpoint (medium down){
                margin-bottom: 0;
            }
        }
        .checkbox-input{
            &::before{
                width: rem-calc(14);
                height: rem-calc(14);
                @include breakpoint (medium down){
                    width: rem-calc(16);
                    height: rem-calc(16);
                    top: 0;
                }
            }
        }
        .checkbox-label{
            @include breakpoint (medium down){
                font-size: rem-calc(12);
                line-height: rem-calc(16);
            }
        }
    }
    .social-network-login-container {
        p {
            margin-bottom: rem-calc(22);
        }
        .social-network-login-symbols {
            text-align: center;
            margin-bottom: rem-calc(50);
            a {
                margin: rem-calc(0 12);
            }
        }

        &.border-top {
            border-top: rem-calc(1) solid $border-gray;
            padding-top: rem-calc(13);
        }
    }
    .registration-boxes-container {
        .myintimissimi-card-registration-box,
        .new-registration-login-box {
            border-top: rem-calc(1) solid $border-gray;
            padding-top: rem-calc(40);
            margin-bottom: rem-calc(10);
            h5{
                font-size: rem-calc(14);
                line-height: rem-calc(18);
                margin-bottom: rem-calc(8);
            }
            p{
                line-height: rem-calc(20);
                margin-bottom: rem-calc(10);

            }
           
        }
        .js-turn-to-register-tab{
            @include breakpoint (medium down){
                margin-bottom: rem-calc(3);
            }
        }
        form.loyalty{
            margin-top: rem-calc(10);
        }
    }
.button-register-margin-40{
    margin-bottom: rem-calc(40);
}
.label-input-loyalty-code {
    width: 76%;
    display: inline-block;
}
.label-input-loyalty-pin {
    width: 20%;
    display: inline-block;
    float: right;
}

.success-block {
    padding: rem-calc(20 12);
    background: $note-blue;
}

// .........registration page..........

.registration-page-title {

    margin-bottom: rem-calc(4);
    margin-top: rem-calc(35);

    @include breakpoint (large up){
        font-size: rem-calc(32);
        font-weight: $global-weight-normal;
    }

    @include breakpoint(medium down) {
        margin-top: rem-calc(32);
        font-size: rem-calc(24);
        font-weight: $global-weight-light;
    }
}

.new-password-page-title {
    margin-bottom: rem-calc(38);
    @include breakpoint(medium up) {
        font-size: rem-calc(32);
    }
    
    @include breakpoint(medium down) {
        font-size: rem-calc(24);
    }
}

.registration-page-form-block {
    max-width: rem-calc(580);
    margin: 0 auto;
}
.phone-number-registration-field {
    padding-left: rem-calc(16);
    &.right {
        padding-left: 0;
        margin-bottom: rem-calc(24);
    }
}

.steps-progress-bar-registration {
    display: flex;
    justify-content: center;
    margin: rem-calc(40 0);
    @include breakpoint (medium down){
        margin: rem-calc(32 0);
    }
    .circle-black {
        height: rem-calc(20);
        width: rem-calc(20);
        border-radius: 50%;
        display: inline-block;
        text-align: center;
        position: relative;
        img {
            top: 50%;
            position: absolute;
            transform: translate(-50%, -50%);
        }
        &.inactive {
            background-color: $border-gray; 
            position: relative;
            &:after {
                content:'';
                width: rem-calc(4);
                height: rem-calc(4);
                border-radius: 50%;
                background: $black; 
                position: absolute;
                top:50%;
                left: 50%;
                margin: rem-calc(-2 0 0 -2); 
            }
        }
    }
    .horizontal-row-step {
        height: rem-calc(20);
        width: rem-calc(25);
        margin: rem-calc(0 5);
        background-color: $white;
        hr {
            display: none;
            margin: 40% 0;
            border-bottom: rem-calc(1) solid $white;
        }
        &.gray {
            background-color: $white;
            hr {
                border-bottom: rem-calc(1) solid $border-gray;
            }
        }
    }
}
.final-title-register {
    margin-bottom: rem-calc(25);
}

.register-form-final-padding-right {
    @include breakpoint (large up){
        padding-right: rem-calc(8);
        margin-bottom: rem-calc(20);
    }
    @include breakpoint (medium down){
        margin-bottom: rem-calc(32);
    }
}
.register-form-final-padding-left {
    @include breakpoint (large up){
        padding-left: rem-calc(8);
        margin-bottom: rem-calc(16);
    }
    @include breakpoint (medium down){
        margin-bottom: rem-calc(32);
    }
}
.text-register-privacy-policy {
    display: inline-block;
    @include breakpoint (medium down){
        margin-top: rem-calc(24);
        margin-bottom: rem-calc(24);
    }
    &.loyalty-condition {
        display: block;
        margin-bottom: rem-calc(16);
        @include breakpoint(large up) {
            margin-bottom: rem-calc(24);
        }
    }
    &.left-disabled-cell{
        width: 100%;
    }
}
.policy-register-form {
    @include breakpoint (large up){
        margin-top: rem-calc(26);
    }
}

.register-form-divider {
    border-bottom: rem-calc(1) solid $cloud;
    margin: rem-calc(40 0);
    width: 100%;
}

.button-register-final {
    @include breakpoint (medium down){
        width: 100%;
    }
}

.link-privacy-policy-condition {
    &.disabled {
        border: 0;
    }
}
.login-datepicker {
    position: relative;
    margin: rem-calc(0 0 16 0);
    @include breakpoint (large up){
        margin: rem-calc(0 0 24 0);
        width: 50%;
    }

    img {
        position: absolute;
        right: rem-calc(24);
        top: rem-calc(48);
    }
}
.cc-toggleColor {
    color: $charcoal;
    @include breakpoint(large up) {
        color: $fossil;
    }
}
.cc-underline {
    text-decoration: underline;
    text-underline-offset: rem-calc(2);
}
.cc-toggleBorder {
    border-color: $cloud;
}
.cc-textToggle{
    font-size: rem-calc(12);
    line-height: rem-calc(18);
    letter-spacing: 0.04em;
    @include breakpoint(medium down) {
        font-size: rem-calc(14);
        line-height: rem-calc(21);
    }
}
.text-color-light-gray {
    color: $dark-gray;
    &.link-gray {
        border-bottom: rem-calc(1) solid $dark-gray;
    }
    &.label-gray {
        &.checkbox-label {
            color: $dark-gray !important;
        }
    }
}

.opposition-field-my-intimissimi {
    padding-right: rem-calc(10);
}

.registration-page-form-block {
    &.email-only-register {
        width: rem-calc(340);
        .hide-email-only {
            display: none;
        }
    }
}

.big-title-my-intimissimi {
    h5{
        font-size: rem-calc(22);
        line-height: rem-calc(28);
        letter-spacing: rem-calc(1);
    }
}

.password-field-with-tooltip {
    position: relative;
    margin-bottom: rem-calc(16);
    i {
        position: absolute;
        top: rem-calc(26);
        right: rem-calc(0);
        @include breakpoint (medium down){
            right: rem-calc(6);
        }
        .tooltip {
            right: 0;
            left: inherit;
            @include breakpoint(medium down) {
                right: -3.5625rem;
            }
        }
    }
}

.modal-opposition {
    .title-modal-opposition {
        margin-bottom: rem-calc(32);
        font-size: rem-calc(20);
    }
}

// .......errori.......

.login-form-error-block {
    position: relative;
    color: $cherry;
    padding: rem-calc(16 16 16);
    border-radius: rem-calc(4);
    border: rem-calc(1) solid $cherry;
    font-size: rem-calc(12);
    text-align: center;
    &::before{
        content: '';
        font-size: rem-calc(12);
        background: url('../images/error.svg');
        width: rem-calc(16);
        height: rem-calc(16);
        background-size: rem-calc(14);
        background-repeat: no-repeat;
        display: inline-block;
        margin: 0;
        top: rem-calc(16);
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        @include breakpoint (medium up){
            position: static;
            transform: translateY(25%);
        }
    }

    @include breakpoint (small down){
        padding: rem-calc(32 16 16);
    }
}
.social-login-form-error:not(:empty){
    background: $note-blue;
    color: $black;
    padding: rem-calc(14 14 11 17);
    &, a{
        font-weight: 500;
        font-size: rem-calc(10);
        line-height: rem-calc(19);
    }
}
.tingle-modal-box {
    button {
        &.close-button-info {
            margin-right: 0;
            position: absolute;
            top: rem-calc(20);
            right: rem-calc(20);
            img {
                width: rem-calc(14);
            }
        }
    }
}

.card-reset-password {
    max-width: rem-calc(346);
    margin: 0 auto;
}

.success-block {
    padding: rem-calc(20 12);
    background: $note-blue;
}

.info-tooltip-reset-password {
    position: absolute;
    top: 39%;
    right: rem-calc(4);
    .tooltip {
        @include breakpoint (medium down){
            left: inherit;
            right: rem-calc(-6);
        }
    }
}

.wrapper-short-with {
    max-width: rem-calc(340);
    margin: 0 auto;
    text-align: center;
}

.registration-phone-confirm-block {
    .sendNewOtp {
        color: $warning;
        border-bottom-color: $warning !important;
    }
    .phoneConfirmCodeBox {
        justify-content: center;
    }
}
.registration-step-final {
    @include breakpoint(medium down){
        margin-bottom: rem-calc(37);
    }
    &.social-registration{
        .registration-grid{
            margin-bottom: rem-calc(7);
            @include breakpoint(medium down){
                margin-bottom: rem-calc(10);
            }
        }
        .policy-register-form{
            margin-top: rem-calc(55);
            @include breakpoint(medium down){
                margin-top: rem-calc(50);
            }
            .prefill-privacy-flag{
                margin: rem-calc(-20 0 30);
                line-height: rem-calc(20);
                @include breakpoint(medium down){
                    margin: rem-calc(-7 0 35);
                }
            }
        }
        .profile-form-customer{
            font-size: rem-calc(14);
            line-height: rem-calc(18);
            @include breakpoint(medium down){
                font-size: rem-calc(13);
                line-height: rem-calc(17);
            }
        }
    }
    .prefilledCustomerData {
        pointer-events: none;
        background: unset;
    }
    .policy-promotion-loyalty{
        @include breakpoint(large up){
            width: 100%;
        }
        .cc-loyaltyContainer{
            display: flex;
            justify-content: space-between;
            @include breakpoint(medium down) {
                flex-direction: column;
            }
            &-box {
                & .text-register-privacy-policy {
                    @include breakpoint(medium down) {
                        margin: rem-calc(0 0 16 0);
                    }
                }
            }
        }
    }
    .text-register-condition-sales{
        display: flex;
        justify-content: space-between;
        gap: rem-calc(16);
        align-items: center;
        margin: rem-calc(40 0 34);
        line-height: rem-calc(20);
        @include breakpoint(medium down){
            flex-direction: column-reverse;
            margin: rem-calc(24 0 24);
        }
        &.already-loyalty{
            margin-top: rem-calc(-17);
            @include breakpoint(medium down){
                margin-top: rem-calc(20);
            }
        }
        .cc-registerButton {
            @include breakpoint(medium down){
                width: 100%;
            }
        }
    }
    .my-intimissimi-card-register{
        margin-bottom: rem-calc(8);
        @include breakpoint(medium down){
            padding-right: rem-calc(20);
        }
    }
    .advantages-subtitle{
        @include breakpoint(large up){
            width: 80%;
        }
    }
    .loyalty-advantages-cell{
        font-size: rem-calc(16);
        .conditions-register{
            font-size: rem-calc(16) !important;
            @include breakpoint(medium down){
                font-size: rem-calc(14) !important;
            }
        }
    }
    .mobile-flex-fields{
        position: relative;
        &.loyalty-flag-cell{
            display: block;
        }
        .popover-loyalty-member-int{
            width: rem-calc(360);
            color: #FFFFFF;
            background: $charcoal;
            position: absolute;
            transform: translateY(-100%);
            top: -100%;
            padding: rem-calc(20 33 30);
            right: rem-calc(-90);
            filter: drop-shadow(rem-calc(1 2 6) rgba($color: $black, $alpha: 0.16));
            font-size: rem-calc(12);
            line-height: rem-calc(17);
            @include breakpoint(medium down){
                right: rem-calc(-35);
                left: unset;
                width: rem-calc(300);
            }
            &:after, &:before {
                bottom: rem-calc(-12);
                right: rem-calc(108);
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-left: rem-calc(8) solid transparent;
                border-right: rem-calc(8) solid transparent;
                border-top: rem-calc(10) solid $charcoal;
                @include breakpoint(medium down){
                    right: rem-calc(54) !important;
                }
            }
            .tooltip-title{
                margin-bottom: rem-calc(18);
                font-size: rem-calc(18);
                line-height: rem-calc(22);
            }
        }
        /* Hide default HTML checkbox */
        .switch input {
            position: absolute;
            opacity: 0;
            pointer-events: none;
        }
        /* The slider */
        .slider {
            position: relative;
            display: inline-flex;
            width: rem-calc(48);
            height: rem-calc(24);
            border-radius: rem-calc(20);
            cursor: pointer;
            background-color: $cloud;
            transition: all 0.6s ease-in-out;
            &:before {
                content: '';
                position: absolute;
                border-radius: rem-calc(20);
                transition: all 0.4s ease-in-out;
                height: rem-calc(20);
                width: rem-calc(20);
                background-color: $white;
                top: 50%;
                transform: translate(0, -50%);
                left: rem-calc(2);
                z-index: 1;
            }
        }
        input:checked + .slider {
            background-color: $charcoal;
        }

        input:checked + .slider:before {
            transform: translate(#{rem-calc(24)}, -50%);
            content: '';
        }
        .slider.loyalty-highlight{
            z-index: 0;
            &::after{
                position: absolute;
                content: "";
                height: rem-calc(50);
                width: rem-calc(50);
                left: rem-calc(-17);
                bottom: rem-calc(-18);
                border-radius: 50%;
                background-color:rgba($color: $black, $alpha: 0.7);
                z-index: -1;
                animation: pulse-ring 1.25s cubic-bezier(0.2, 0.5, 0.3, 1) infinite;
            }
        }
        @keyframes pulse-ring {
            0% {
                transform: scale(.5);
            }
            80%, 100% {
                opacity: 0;
            }
        }
    }
    .opposition{
        .cc-opposition_checkBox {
            display: flex;
            flex-flow: row wrap;
            align-items: center;
        }
        .opposition-field-my-intimissimi{
            margin: 0;
            padding-right: rem-calc(8);
            flex: unset;
            .checkbox-input{
                padding-left: rem-calc(20);
                &::before{
                    width: rem-calc(18);
                    height: rem-calc(18);
                }
            }
        }
        .opposition-label{
            color: #707070;
        }
        .js-modal-tooltip-opposition{
            display: inline-flex;
        }
    }
}

.landing-page-Welcome-Series{
    @include breakpoint (medium down){
        padding: 0;
    }
    .welcome-image-cell{
        img{
            width: 100%;
        }
        position: relative;
        .welcome-text-box{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -65%);
            text-align: center;
            width: rem-calc(237);
            font-size: rem-calc(20);
            @include breakpoint (medium down){
                transform: translate(-50%, -50%);
            }
            span{
                display: block;
            }
            .welcome-title{
                margin-bottom: rem-calc(35);
                font-size: rem-calc(24);
                font-weight: 500;
                @include breakpoint (medium down){
                    font-size: rem-calc(22);
                }
            }
        }
    }
    .welcome-login-cell{
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
        padding: rem-calc(50 35 20);
        @include breakpoint (medium down){
            padding: rem-calc(35 50);
        }
        .text-box-login{
            @include breakpoint (large up){
                width: 70%;
                margin: 0 auto;
            }
            .welcome-title{
                display: block;
                font-size: rem-calc(22);
                margin-bottom: rem-calc(20);
                font-weight: 500;
                text-align: center;
                @include breakpoint (medium down){
                    font-size: rem-calc(20);
                    margin-bottom: rem-calc(35);
                }
            }
            .welcome-desc{
                display: block;
                font-size: rem-calc(14);
                font-weight: 400;
                margin-bottom: rem-calc(20);
                text-align: center;
            }
        }
        .content-box-login{
            margin-top: rem-calc(30);
            @include breakpoint (medium down){
                margin-top: rem-calc(0);
                padding-top: rem-calc(50);
            }
            .welcome-options-title{
                font-size: rem-calc(16);
                font-weight: 500;
                display: block;
                margin-bottom: rem-calc(25);
                @include breakpoint (medium down){
                    margin-bottom: rem-calc(40);
                }
            }
            .welcome-options{
                display: flex;
                align-items: center;
                font-weight: 400;
                margin-bottom: rem-calc(25);
                @include breakpoint (medium down){
                    margin-bottom: rem-calc(20);
                }
                img{
                    width: rem-calc(20);
                    margin-right: rem-calc(16);
                    @include breakpoint (medium down){
                        margin-top: rem-calc(4);
                    }
                }
            }
        }
    }
}

#password-reset{
    width: 100%;
    margin-bottom: rem-calc(40)
}
#loyalty-form-cardCode{
    margin-bottom: rem-calc(24);
}
.login-form-error:empty{
    margin: 0;
}
.gigyaLoginCell{
    margin: 0 auto;
    .login-register-gigya{
        font-size: rem-calc(14);
        line-height: rem-calc(18);
        margin-bottom: rem-calc(20);
        @include breakpoint (medium down){
            font-size: rem-calc(12);
            line-height: rem-calc(20);
            margin-bottom: rem-calc(16);
        }
    }
    .social-login-account-accept{
        margin-top: rem-calc(16);
        font-size: rem-calc(12);
        line-height: rem-calc(16);
    }
}
.socialLoginContainer {
    margin: 0 auto;
    tr{
        background-color: white !important;
    }
    table[align="center"],
    td[align="center"]{
        width: 100%;
    }
    td[align="right"],
    td[align="left"]{
        display: none;
    }
}
.price-info-pdp-container {
    color: $component-grey;
    font-size: rem-calc(12);
    font-weight: 400;
    line-height: rem-calc(18);
    margin-top: rem-calc(4);

    @include breakpoint (medium down) {
        margin-bottom: rem-calc(15);
        margin-top: rem-calc(4);
    }

    p.price-info {
        color: $fossil;
        display: inline-block;
        font-size: rem-calc(12);
        font-weight: 400;
        line-height: rem-calc(18);
    }

    &.plp {
        font-size: rem-calc(10);
        line-height: rem-calc(14);
    }
}
.login-cta.for-modal{
    margin-bottom: rem-calc(16);
}

.form-fields-item {
    @include breakpoint(medium down) {
        margin-bottom: rem-calc(23);
    }

    &__label {
        width: 100%;
        margin-bottom: 0;
        @include breakpoint (large up){
            max-width: rem-calc(556.5);
        }
        @include breakpoint (medium down){
            width: 100%;
            max-width: 100%;
        }
    }
}

.cc-my-intimissimi-privacy-policy {
    line-height: 150%;
}

.is-invalid-label {
    select,
    input {
        background-color: $white;
        border: rem-calc(1) solid $alert;
        margin-bottom: rem-calc(15);
    }
}

.cell{
    &.large-3{
        &.small-4{
            &.text-right{
                width: 100%;
                align-self: flex-start;
                justify-content: flex-start;
                display: flex;
            }
        }
    }
}

.add-to-email-list-yes {

}

.add-to-personalized-news-list-yes {
    @include breakpoint(medium down) {
        margin-top: rem-calc(30);
    }
}

.register{
    &-form{
        &-content{
            width: 54.17%;
            flex: 0 0 auto;
            @include breakpoint (medium down){
                width: 100%;
            }
        }
        &-input{
            width: 50%;
            @include breakpoint (medium down){
                width: 100%
            }
        }
        &-privacy{
            &-text{
                width: 100%;
                @include breakpoint(large up) {
                    margin-top: rem-calc(16);
                }
            }
            &-link{
                margin: rem-calc(32 0 24);
                @include breakpoint (large up) {
                    width: 100%;
                    margin: rem-calc(14 0 54 0);
                }
            }
        }
        &-genderSelect{
            @include breakpoint (large up){
                width: 49%;
                margin-bottom: rem-calc(8);
            }
            @include breakpoint (medium down){
                width: 100%;
                max-width: 100%;
            }
        }
        &-button{
            margin: 0;
            @include breakpoint (medium down){
                width: 100%;
                margin-top: rem-calc(16);
            }
        }
    }
}
.no-margin{
    .profile-form-customer{
        white-space: pre-line;
    }
}

#linkToBeRendered{
    @include breakpoint (large up){
        margin-bottom: 0;
    }
}

.tick {
    &-mocha{
        color: $mocha;
        width: rem-calc(20);
        height: rem-calc(20);
    }
    &-fossil{
        color: $fossil;
        width: rem-calc(20);
        height: rem-calc(20);
    }
}

.close {
    &-white{
        color: $white;
        margin-top: rem-calc(24);
        margin-right: rem-calc(-2);
    }
}
.cc-registerPolicy {
    display: flex;
    flex-flow: wrap;

    @include breakpoint(large up) {
        margin-top: rem-calc(14);
        margin-bottom: rem-calc(8);
    }

    &-label {
        flex: 0 0 auto;
        margin-bottom: 0;
        margin-right: rem-calc(24);
    }

    .errors-list.filled {
        width: 100%;
        flex: none;
        margin-top: rem-calc(16);
    }
}

.cc-text-form-field {
    margin-top: rem-calc(4);
    @include breakpoint(medium down) {
        margin-top: 0;
    }
}

.cc-text-form-field {
    margin-top: rem-calc(4);
    @include breakpoint(medium down) {
        margin-top: 0;
    }
}

.cc-prefix-cell {
    width: 17%;
    @include breakpoint(medium down) {
        width: 29%;
    }
}

.cc-phone-cell {
    width: 83%;
    @include breakpoint(medium down) {
        width: 71%;
    }
}

.cc-margin {
    &-t {
        &-sm {
            margin-top: rem-calc(12);
        }
        &-xl {
            margin-top: rem-calc(42);
        }
    }

    &-b {
        &-xs {
            margin-bottom: rem-calc(5);
        }
        &-sm {
            margin-bottom: rem-calc(12);
        }
        &-lg {
            margin-bottom: rem-calc(35);
        }
    }
}

.cc-width {
    &-md {
        width: rem-calc(580);
        @include breakpoint (medium down){
            width: 100%;
        }
    }
}
